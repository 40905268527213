<template>
  <div id="app">
    <div class="main">
      <div class="banner">
        <div class="bannerLeft" v-if="appData.tips_desc">
<!--          <img class="logo" :src="appData.logo" alt="">-->
                  <span class="appName">{{appData.tips_desc}}</span>
        </div>
        <div class="bannerRight">
          <img class="bannerIcon" src="../static/image_0.png" alt="">
          <div>
            <div class="phoneText">服务咨询热线</div>
            <div  class="phoneNumText">{{appData.mobile}}</div>
          </div>
        </div>
      </div>
      <div class="imgLine" @click="openChat"><img class="pageImg" src="../static/image1.jpg" alt=""></div>
      <div class="imgLine"><img class="pageImg" src="../static/image2.jpg" alt=""></div>
      <div class="imgLine"><img class="pageImg" src="../static/image3.jpg" alt=""></div>
      <div class="imgLine"><img class="pageImg" src="../static/image4.jpg" alt=""></div>
      <div class="imgLine"><img class="pageImg" src="../static/image5.jpg" alt=""></div>
      <div class="imgLine"><img class="pageImg" src="../static/image6.jpg" alt=""></div>
      <div class="imgLine"  @click="openChat"><img class="pageImg" src="../static/image7.jpg" alt=""></div>
    </div>
    <div class="form">
      <div class="formName"><strong>您的需求？</strong></div>
      <div class="formInto">提交定制需求，免费评估</div>
      <div class="formLine">
        <input class="formIpt" type="text" v-model="firstForm.demand" placeholder="请输入您的需求"></input>
      </div>
      <div class="formLine">
        <input class="formIpt" type="text" v-model="firstForm.mobile" placeholder="请输入手机号码"></input>
      </div>
<!--      <div class="formSecondLine">-->
<!--        <input class="formIptHalf" type="text" placeholder="请输入验证码"></input>-->
<!--        <div class="getCodeBtn" id="getCodeBtn">发送验证码</div>-->
<!--      </div>-->
      <div class="button" @click="checkForm(1)">
        <span>立即咨询<span class="animation-light"></span></span>
      </div>
    </div>

    <div class="carousel">
      <div class="carousel-inner" :style="carouselStyle">
        <div class="carousel-item" v-for="(item, index) in swiperData" :key="index"
             :class="{ active: index === currentIndex }">
          <div class="name">
            <span class="nameText">{{ item.name }}</span>
          </div>
          <div class="phone">{{ item.phone }}</div>
          <div class="status">{{ item.type }}</div>
        </div>
      </div>

    </div>


    <footer>
      <div class=" footerLine" @click="openChat" ><img class="footerImg" src="../static/image8.jpg" alt=""></div>
      <div class="appInfo">
        <span >{{appData.title}}</span>
        <span class="address">{{appData.address}}</span>
      </div>
    </footer>


    <div class="qr">
      <img class="qrImg" :src="appData.qr_code || ''" alt=""></img>
    </div>
<!--    <div class="right">-->
<!--      <img class="ai" src="../static/ai.gif" alt="">-->
<!--      <div class="blueLine">-->
<!--        <div class="blueLineTop" @click="dialogVisible=true">-->
<!--          <i class="el-icon-service iconText"></i>-->
<!--          <div class="text free">免费 <br/>回电</div>-->
<!--        </div>-->
<!--        <el-popover-->
<!--            placement="left"-->
<!--            width="200"-->
<!--            trigger="hover"-->
<!--            content=""-->
<!--        >-->
<!--          <div class="phoneBox">-->
<!--            <div class="phoneText">客服电话</div>-->
<!--            <div class="phoneNum">{{ appData.mobile }}</div>-->
<!--          </div>-->
<!--          <div class="blueLineBottom" slot="reference">-->
<!--            <i class="el-icon-phone-outline iconText"></i>-->
<!--            <div class="text call"> 电话<br/>咨询</div>-->
<!--          </div>-->
<!--        </el-popover>-->
<!--      </div>-->
<!--    </div>-->


    <el-dialog
        :visible.sync="dialogVisible"
        width="400px"
    >
      <div class="secondForm">
        <div class="formName"><strong>提交定制需求免费评估</strong></div>
        <div class="formLine">
          <input class="formIpt" type="text" v-model="secondForm.demand" placeholder="请输入您的需求"></input>
        </div>
        <div class="formLine">
          <input class="formIpt" type="text" v-model="secondForm.mobile" placeholder="请输入手机号码"></input>
        </div>
<!--        <div class="formSecondLine">-->
<!--          <input class="formIptHalf" type="text" placeholder="请输入验证码"></input>-->
<!--          <div class="getCodeBtn" id="getCodeBtn">发送验证码</div>-->
<!--        </div>-->
        <div class="button" @click="checkForm(2)">
          <span>立即咨询<span class="animation-light"></span></span>
        </div>
        <div class="cllNum">专业顾问会在24小时内联系您</div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  data() {
    return {
      currentIndex: 0,
      countdown: 60,
      requestUrl: "https://ww.hnjrdzkj.cn",
      firstForm:{
        demand:"",
        mobile:""
      },
      secondForm:{
        demand:"",
        mobile:""
      },
      swiperData: [
        {
          name: "张*林",
          phone: "130*****096",
          type: "提交成功"
        },
        {
          name: "李*伟",
          phone: "130*****087",
          type: "提交成功"
        },
        {
          name: "王*芳",
          phone: "136*****238",
          type: "提交成功"
        }, {
          name: "张*杰",
          phone: "152*****274",
          type: "提交成功"
        }
        , {
          name: "刘*婷",
          phone: "156*****144",
          type: "提交成功"
        }
        , {
          name: "陈*",
          phone: "158*****265",
          type: "提交成功"
        }
        , {
          name: "杨*洁",
          phone: "176*****277",
          type: "提交成功"
        }
        , {
          name: "赵*强",
          phone: "183*****272",
          type: "提交成功"
        }
        , {
          name: "黄*颖",
          phone: "182*****562",
          type: "提交成功"
        }
        , {
          name: "吴*军",
          phone: "150*****356",
          type: "提交成功"
        }
        , {
          name: "周*莉",
          phone: "183*****563",
          type: "提交成功"
        }

      ],
      dialogVisible: false,
      appData: {},
    }
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateY(-${this.currentIndex * 100}%)`,
        transition: 'transform 0.5s ease-in-out'
      };
    }
  },
  mounted() {
    // this.getUserList();
    this.getimgLink();
    setInterval(this.next, 2000); // 每3秒自动切换
  },

  created() {

  },
  methods: {

    next() {
      this.currentIndex = (this.currentIndex + 1) % this.swiperData.length;
    },

    openChat(){
      window.open(
          'https://tb.53kf.com/code/client/eb8001886b01a8774a111c5d5bfb4a784/1'
      )
    },


    startCountdown() {
      this.timer = setInterval(function () {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer); // 清除计时器
          this.countdown = 60; // 重置倒计时
          this.isGetCode = false;
        }
      }, 1000);
    },


    async getimgLink() {
      axios.get("https://ww.hnjrdzkj.cn/api/index/apiConfig", {async: false}).then(res => {
        this.imageLink = res.data.data.img_url;
        this.appData = Object.assign({}, res.data.data)
      })

    },
    checkForm(type){
      if(type===1){
        if(this.firstForm.demand===""||this.firstForm.mobile===""){
          this.$message.error("请填写完整信息");
          return false;
        }else{
          this.submitForm(type);
        }
      }else if(type===2){
        if(this.secondForm.demand===""||this.secondForm.mobile===""){
          this.$message.error("请填写完整信息");
          return false;
        }else{
          this.submitForm(type);
        }
      }
    },

    submitForm(type) {
      let linkData = "";

      axios.post(this.requestUrl + "/api/index/consult", type ===1?this.firstForm:this.secondForm)
          .then(res => {
            this.$message.success(res.data.msg);
            if(type ===1){
              this.firstForm = {
                demand:'',
                mobile:''
              };
            }else{
              this.secondForm = {
                demand:'',
                mobile:''
              };
            }
          })
          .catch(err => {
            console.log(err);
          })

      // axios.get(this.requestUrl + `/api/index/consult?${linkData}`, {})
      //     .then(res => {
      //      this.$message.success(res.data.msg);
      //     })
      //     .catch(err => {
      //       console.log(err);
      //     })
    },


  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0 8px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes templateSearchLights {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100px);
    transform: translate(-100px)
  }

  66% {
    opacity: 1;
    -webkit-transform: translate(-100px);
    transform: translate(-100px)
  }

  to {
    -webkit-transform: translate(800px);
    transform: translate(800px)
  }
}

.main, footer {
  max-width: 1200px;
  margin: 0 auto;
}

.banner{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 150px;
  font-size: 16px;
}
.bannerLeft{
  display: flex;
  align-items: center;
  width: 500px;
  height: 100px;
}
.bannerRight{
  display: flex;
  align-items: center;

}
.logo{
  max-width: 195px;
  max-height: 50px;
  margin-right: 10px;
}
.appName{
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}
.bannerIcon{
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.phoneNumText{
  color:  rgb(56, 82, 239);
  line-height: 30px;
}

.imgLine {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;
}
.footerLine{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
}
.footerImg{
  max-width: 1200px;
}

.pageImg {
  width: 100%;
  height: auto;
}

.appInfo{
  height: 200px;
  text-align: center;
  line-height: 80px;
}
.appInfo .address{
  padding-left: 10px;
}

.qr {
  position: fixed;
  max-width: 100%;
  left: 0;
  top: calc(50% + 0px);
  transform: translateY(-50%);
}


.form {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0;
}

.secondForm {
  width: 350px;
}

.formName {
  text-align: center;
  font-size: 24px;
  color: #000000;
}

.secondForm .formName {
  text-align: center;
  font-size: 18px;
  color: #000000;
  line-height: 80px;
}

.formInto {
  text-align: center;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
  margin-bottom: 10px;
}

.formLine {
  margin-bottom: 20px;
}

.formIpt, .formIptHalf {
  font-size: 14px;
  border-radius: 4px;
  background-color: #fff;
  border-color: #dcdfe6;
  border-width: 1px;
  height: 40px;
  line-height: 40px;
  outline: none;
  color: inherit;
  margin: 0;
  width: 100%;
  max-width: 1200px;
  padding-left: 5px;
}

.formSecondLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.formIptHalf {
  width: 50%;
}

.getCodeBtn {
  background-color: rgb(239, 83, 80);
  border-color: rgb(239, 83, 80);
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.button {
  background-color: rgb(239, 83, 80);
  border-color: rgb(239, 83, 80);
  border-radius: 20px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  width: 90%;
  left: 10%;
  height: 40px;
  line-height: 40px;
  margin-top: 0px;
  background-image: linear-gradient(45deg, rgb(239, 83, 80), rgb(255, 63, 60));
  text-align: center;
  position: relative;
  cursor: pointer;
}

.cllNum {
  padding-top: 20px;
  text-align: center;
}

.animation-light {
  animation: templateSearchLights 3s linear infinite forwards;
  background-image: url("/static/t0113933f686125c13b.png");
  background-size: 100px 40px;
  width: 100px;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
}

.carousel {
  max-width: 600px;
  height: 80px;
  overflow: hidden;
  background: rgba(239, 83, 80, 0.08);
  margin: 20px auto;
  border-radius: 15px;
}

.carousel-inner {
  width: 100%;
  height: 100%;
}

.carousel-item {
  height: 80px;
  color: #ef5350;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.5s ease-in-out;
  padding: 0 15px;
}

.qr {
  width: 150px;
}

.qrImg {
  width: 100%;
}

.right {
  position: fixed;
  max-width: 100%;
  top: calc(50% + 0px);
  right: 0px;
  transform: translateY(-50%);
}

.ai {
  width: 90px;
  height: 90px;
}

.blueLine {
  background: linear-gradient(rgb(84, 165, 255) 0%, rgb(56, 82, 239) 100%);
  color: #ffffff;
  border-radius: 40px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin: 0 20px;
}

.blueLineTop, .blueLineBottom {
  padding: 12px 9px;
  width: 40px;
}

.blueLineTop {
  padding-bottom: 5px;
}

.blueLineBottom {
  padding-top: 5px;
}

.blueLineTop:hover {
  border-radius: 40px 40px 0 0;
  background: linear-gradient(rgb(84, 165, 255) 0%, rgb(56, 82, 239) 100%);
}

.blueLineBottom:hover {
  border-radius: 0 0 40px 40px;
  background: linear-gradient(rgb(84, 165, 255) 0%, rgb(56, 82, 239) 100%);
}

.iconText {
  font-size: 20px;
  padding-top: 5px;
}

.free, .call {
  padding: 10px 0;
}

.call {
  padding-bottom: 20px;
}

.phoneBox {
  padding: 20px;
  text-align: center;
}

.phoneText {
  font-size: 16px;
  color: #000000;
}

.phoneNum {
  line-height: 30px;
  color: rgb(56, 82, 239);
}

</style>
